/* eslint-disable no-undef */
import React, { useEffect,useState } from "react";
import axios from 'axios'
import SliderComp from "../../components/SliderComp";

export default function Products() {
    const [products, setProducts] = useState([])

    useEffect(() => {
        new Swiper('.blog-slider', {
            spaceBetween: 30,
            effect: 'fade',
            loop: true,
            mousewheel: {
              invert: false,
            },
            // autoHeight: true,
            pagination: {
              el: '.blog-slider__pagination',
              clickable: true,
              renderBullet: function (index, className) {
                if (index < 15) {
                    return '<span class="' + className + '">' + (index + 1) + '</span>';
                }
                return '';
            },
            }
          });
    }, [products])

    useEffect(() => {
        getProducts()
        }, [])
        const getProducts = async () => {
            try {
                const res = await axios.get('https://webadmin.ceylonheritage.lk/api/product_import')
                setProducts(res.data)
                console.log(res)
            } catch (error) {
                console.error(error)
            }
        }
  return (
    <>
    {
        products && Array.isArray(products) && products.length > 0 && products.map((item, index) => (
            <div className="">
                <h2 className="text-3xl font-bold text-black text-center my-10">Our {item.category} Products</h2>
            <div class="blog-slider mt-20">
                <div class="blog-slider__wrp swiper-wrapper">
                {item.items.map(itemy => (
                    <SliderComp item={itemy} key={itemy.id} />
                ))}
                </div>
                <div class="blog-slider__pagination max-w-fit"></div>
            </div>
            </div>
        ))
    }    
       
    </>
    )
}
