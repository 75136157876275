import React, { useEffect, useState }  from 'react'
import Reveal from '../../../components/Reveal/Reveal'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import Footer from '../../../components/footer'

export default function Field() {

    const { field , title } = useParams()

    const [jobDescriptions , setJobDescriptions] = useState([])

    const getCareers = async () => {
        try{
            const response = await axios.get(`https://webadmin.ceylonheritage.lk/api/careerstype/${field}`)
            console.log(response.data)
            setJobDescriptions(response.data[0].jobs)
        }catch(err){
            console.log(err)
        }
    }

    useEffect(() => {
        getCareers()
    },[])

    const applyNowHandler = async (job) => {
        console.log(job);
        const subject = encodeURIComponent(`Application for ${job.title}`);
        const body = encodeURIComponent(`Please write a cover letter.And also send your CV.`);
        window.location.href = `mailto:careers@ceylonheritage.lk?subject=${subject}&body=${body}`;
    };
    
  return (
    <section exit={{ opacity: 0 }} id="Careers" className="bg-white min-h-screen mt-16">
        <div className="container text-start flex flex-col w-full px-4 lg:w-10/12 mx-auto mt-16 space-y-12 md:space-y-0">
            <Reveal>
                <h2
                className="text-4xl capitalize w-full  mt-7 font-bold text-center text-black "
                >
                opening positions for {title}
                </h2>
            </Reveal>
            <Reveal>

            {jobDescriptions &&
                        jobDescriptions.length > 0 &&
                        jobDescriptions.map((job, index) => {
                            const number = index + 1;
                            const totalJobs = jobDescriptions.length;
                            const formattedNumber = number < 10 ? `0${number}` : number;

                            return (
                                <div key={index} className="bg-white text-start border mt-4 p-6 rounded-lg shadow-md">
                                    <div className="flex text-start items-center justify-between mb-4">
                                        <span className="text-lg font-bold text-[#5C6A7A]">
                                            Job {formattedNumber}/{totalJobs}
                                        </span>
                                    </div>
                                    <span className="text-[21px] font-bold capitalize">{job.title}</span>
                                    <p className="text-gray-700 mb-4 capitalize">{job.description}</p>
                                    <div>
                                        <span className="text-black text-[17px] font-bold mb-2">
                                            Roles & Responsibilities
                                        </span>
                                        <ul className="list-disc list-inside pl-5">
                                            {job.responsibilities.map((responsibility, index) => (
                                                <li key={index}>{responsibility.responsibilities}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <span className="text-black text-[17px] font-bold ">Qualification Requirements</span>
                                    <ul className="list-disc list-inside pl-5">
                                        {job.qualifications.map((qualification, index) => (
                                            <li key={index}>{qualification.qualifications}</li>
                                        ))}
                                    </ul>
                                    <span className="text-black text-[17px] font-bold ">Selection Criteria</span>
                                    <ul className="list-disc list-inside pl-5">
                                        {job.criteria.map((criteria, index) => (
                                            <li key={index}>{criteria.criteria}</li>
                                        ))}
                                    </ul>
                                    <div className="mt-4 flex w-full flex-end justify-endw">
                                        <button
                                            onClick={() => applyNowHandler(job)}
                                            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 focus:outline-none"
                                        >
                                            Apply Now
                                        </button>
                                    </div>
                                </div>
                            );
                        })}
            </Reveal>
        </div>
        <Footer/>
    </section>

  )
}
