import React, { useRef, useState } from "react";

const itemsData = [
  {
    backgroundImage: './hands-holding-fresh-grain-harvest.png',
    title: 'From where we export',
    description: `We export from GCC Countries (Bahrain, Kuwait, Oman, Qatar, Saudi Arabia, and the United Arab Emirates), Maldives and the UK.`,
  },
  {
    backgroundImage: './cheerful-farmer-with-organic-vegetables-garden-mixed-organic-vegetable-man-s-hands.png',
    title: 'Why we export',
    description: `Exporting is at the core of Ceylon Heritage's mission, driven by a commitment to uplifting the livelihoods of farming communities. Recognizing the historical exploitation faced by these dedicated farmers, we engage directly with them, fostering partnerships that ensure fair compensation for their efforts. At Ceylon Heritage, we believe in more than just commerce; we believe in empowering local communities, contributing to the economic well-being of the farmers, and creating sustainable opportunities that resonate far beyond our exports. By purchasing their fresh produce at fair prices, we not only support these farmers but also ensure the continuity of their traditions and the prosperity of their communities.`,
  },
  {
    backgroundImage: './back-view-countryside-worker-out-field.jpg',
    title: 'What we export',
    description: `We export fresh fruits, fresh vegetables, leafy greens, coconut based products, spices, fresh cut fruit, juices and jams. Our promise is to reengineer our process to keep our brand promise to customers which is that all products will be delivered from the farm to the shelf within 24 hours.`,
  },
  
];

export default function ImportHeader() {
    const sliderRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(1);
    
    const activate = (e) => {
      const items = sliderRef.current.querySelectorAll('.item');
      if (e.target.matches('.next')) {
        sliderRef.current.append(items[0]);
        setActiveIndex((prevIndex) => (prevIndex + 1) % items.length);
      }
      if (e.target.matches('.prev')) {
        sliderRef.current.prepend(items[items.length - 1]);
        setActiveIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
      }
    };
    
  return (
    <div className="min-h-screen">
    <ul className='slider flex flex-col' ref={sliderRef}>
    {itemsData.map((item, index) => (
          <li
            key={index}
            className={index === activeIndex ? 'item active' : index === (activeIndex + 1) % itemsData.length ? 'item hidden-item' : 'item'}
            style={{ backgroundImage: `url(${item.backgroundImage})` }}
          >
            <div className={index === activeIndex ? 'content active' : 'content'}>
              <h2 className='title'>{item.title}</h2>
              <p className='description'>{item.description}</p>
            </div>
          </li>
        ))}
  </ul>
  <nav className='nav flex gap-3'>
      <button className="prev px-2 py-2 text-white text-[28px]" onClick={activate}>{`<`}</button>
  <button className="next px-2 py-2 text-white text-[28px]" onClick={activate}>&gt;</button>
  </nav>
  </div>
  )
}
