/* eslint-disable no-undef */
import React from "react";
import ImportHeader from "./ImportHeader";
import Products from "./Products";
import Footer from "../../components/footer/index"

export default function Department() {

  return (
    <>
    <div id="main" className="gap-24 flex flex-col items-center justify-between">
      <ImportHeader />
      {/* <div className="h-[100px]">

      </div> */}
      <Products />
      <Footer />
    </div>
    </>
  )
}
