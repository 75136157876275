import React ,{ useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import HomePage from '../pages/home/page';
import ImportPage from '../pages/import/page';
import ExportPage from '../pages/export/page';
import ContactUs from '../pages/contactus/page';
import Careers from '../pages/careers/page';
import SplashScreen from '../components/SplashScreen/SplashScreen'
import Navigation from '../components/navigation/index'
import AboutUsPage from '../pages/about-us/page'
import CareerField from '../pages/careers/field/page'
import OurGallery from '../pages/our-gallery/page'
import Page404 from '../pages/404/Page404';

const ProjectRoutes = () => {

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 2400)
  }, [])

  return (
    <Router>
    {window.location.pathname !== '/' && isLoading ? (
      <SplashScreen />
    ) : (
      <>
        <Navigation />
        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path="/import" exact component={ImportPage} />
          <Route path="/export" exact component={ExportPage} />
          <Route path="/contactus" exact component={ContactUs} />
          <Route path="/careers" exact component={Careers} />
          <Route path="/about-us" exact component={AboutUsPage} />
          <Route path="/careers/:field/:title" exact component={CareerField} />
          <Route path="/our-gallery" exact component={OurGallery} />
          <Route path="*" component={Page404} />
        </Switch>
      </>
    )}
  </Router>
  );
};

export default ProjectRoutes;
