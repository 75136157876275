import React from 'react'

function Cards({item}) {
  return (
    <div className="bg-white w-full h-[363px] hover:shadow-lg border relative py-4 px-2 rounded-md hover:scale-110 transition-all duration-500">
    <img src={`https://webadmin.ceylonheritage.lk/public/ourteam/${item.id}/${item.image}`} alt="" width={36} height={36} className="w-36 h-36 rounded-full inline-block" />
    <div className="mt-4 ">
      <h4 className="text-base font-extrabold">{item.name}</h4>
      <p className="text-xs mt-1">{item.position}</p>
      <p className="text-s mt-3 font-bold">{item.tittle}</p>
      <div className="space-x-4 mt-4">
       
        <a href={item.linkedin_url} target="_blank" rel="noreferrer" className="cursor-pointer w-7 h-7 inline-flex items-center justify-center rounded-full border-none outline-none  bg-gray-100 hover:bg-gray-200">
          <svg xmlns="http://www.w3.org/2000/svg" width="14px" fill="#333" viewBox="0 0 24 24">
            <path
              d="M23.994 24v-.001H24v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07V7.976H8.489v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243V24zM.396 7.977h4.976V24H.396zM2.882 0C1.291 0 0 1.291 0 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909A2.884 2.884 0 0 0 2.882 0z"
              data-original="#0077b5" />
          </svg>
        </a>
      </div>
    </div>
  </div>
  )
}
export default Cards