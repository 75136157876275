import React, { useEffect, useState } from 'react'
import Footer from '../../components/footer'
import axios from 'axios'

export default function Page() {

  const [gallery, setGallery] = useState([])
  const getAlldata = async () => {
    try {
      const response = await axios.post(`https://webadmin.ceylonheritage.lk/api/gallery`)
      setGallery(response.data)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    getAlldata()
  }, [])

  return (
    <div className='mt-[110px]'>
    <h1 className='flex items-center text-4xl font-bold py-10 w-full text-center justify-center'>Our Gallery</h1>
    <div id="gallery" className="container-fluid px-3">  
    {
      gallery && Array.isArray(gallery) && gallery.length > 0 && gallery.map((item, index) => (
        <img key={index} alt={item.image} src={`https://webadmin.ceylonheritage.lk/public/gallery/${item.id}/${item.image}`} className="img-responsive"/>
      ))
    
    }
    </div>
    <Footer />
    </div>
  )
}
