// components/SplashScreen.js
import React from 'react'
import Lottie from "lottie-react"
import loadingAnimation from "../../assets/animations/loadingAnimation.json"

const SplashScreen = () => {
  return (
    <div className="splash-screen">
      {/* Your splash screen content */}
      <Lottie animationData={loadingAnimation} className='w-[340px]' loop={true} />
    </div>
  )
}

export default SplashScreen
