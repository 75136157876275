import React from 'react'
import axios from 'axios'
import ScrollAnimation from '../../components/scrollAnimation/index'
import Reveal from '../../components/Reveal/Reveal'
import gmp from '../../assets/images/gmp.png'
import haccap from '../../assets/images/haccap.png'
import iso from '../../assets/images/iso.png'
import Cards from '../../components/Cards/Cards'
import Footer from '../../components/footer'
// import Timeline from '../../components/Timeline/Timeline'

export default function AboutUs() {

  const [ourTeam , setOurTeam ] = React.useState([])

  const fetchOurTeam = async () => {
    try {
      const response = await axios.get('https://webadmin.ceylonheritage.lk/api/ourteam')
      setOurTeam(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  React.useEffect(() => {
    fetchOurTeam()
  }, []);


  return (
    <>
      <ScrollAnimation/>
      <section  id="hero" className="bg-white mt-[60px] min-h-screen" >
        <div className="container  text-[15px] flex flex-col px-4 w-full lg:w-10/12 mx-auto py-16 space-y-12 md:space-y-6 ">
          <div 
            >
            <Reveal>

            <h1 className='flex items-center text-4xl font-bold py-10 text-black w-full text-center justify-center'>About Us</h1>

            </Reveal>
           
            <div 
              className='grid slide-in-contacts  font-normal grid-flow-row gap-4 text-[15px] mt-5 '>
              {/* { */}
                {/* aboutUs.map((item, index) => ( */}
            <Reveal>

                  <p  className="text-justify">
                  Welcome to Ceylon Heritage Collections (CHC) – a pioneering venture founded in 2011 with a singular mission: to share the essence of Ceylon with the world. Rooted in a commitment to excellence, ethical trade, and environmental care, we at CHC collaborate closely with growers to bring you the finest selection of fruits, vegetables, leafy greens, spices, and more.
                  </p>
            </Reveal>

                  {/* <div  className="text-justify">
            <Reveal>

                  <img
                    src={image01}
                    alt="videoShow"
                    sizes="100vw"
                    style={{ width: '100%', height: 'auto'  }} // optional
                    />
            </Reveal>

                  </div> */}
          
                  <Reveal>
                    <p  className="text-justify">
                    At the heart of our operation is a dedication to maintaining the highest standards. We prioritize quality at every step, from harvesting our produce at its optimal maturity to meticulous hand selection for quality and maturity at our processing center, all on the same day of harvest. This ensures that each item we export embodies the rich flavors and goodness of Ceylon.
                    </p>
                  </Reveal>
                  <Reveal>
                    <p  className="text-justify">
                    Specializing in the export of Ceylonese products, including fresh fruits, vegetables, spices, dry foods, and coconut oil, CHC is the preferred choice for consumers who prioritize reliability. We are not merely exporters; we are facilitators of growth and prosperity within our local communities. Motivating growers to participate in the global market, we strive to enhance the well-being of the locality through their produce.
Diversity is the cornerstone of our Joint Effort Enterprise. We actively seek to break down barriers and celebrate the unique qualities that make us different. Our culture is integral to our identity, founded on the belief that care for our people translates into care for our produce, fostering a natural respect for the environment and the communities we impact.
                    </p>
                  </Reveal>
                  <Reveal>
                    <p  className="text-justify">
                    CHC upholds a paramount level of professionalism, integrity, honesty, and fairness in all our relationships—with growers, professional associates, and customers alike. Guided by our five core values—Integrity, Quality, Sustainability, Human Capital, and Innovation—we continuously strive to set benchmarks in the industry.
                    </p>
                  </Reveal>
                  <Reveal>
                    <p  className="text-justify">
                    Join us on a journey of authenticity, quality, and shared prosperity. Ceylon Heritage Collections is not just a company; it's a commitment to excellence that transcends borders and brings the best of Ceylon to your table.
                    </p>
                  </Reveal>
                  {/* <div  className="text-justify">
                    <Reveal>
                      <img
                        src={image03}
                        alt="videoShow"
                        sizes="100vw"
                        style={{ width: '100%', height: 'auto'  }} // optional
                        />
                  </Reveal>
                  </div> */}
{/*                  
                  <Reveal>
                    <p  className="text-justify">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&apos;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum
                    </p>
                  </Reveal> */}
{/* 
                  <div  className="text-justify">
                    <Reveal>
                      <img
                        src={image05}
                        alt="videoShow"
                        sizes="100vw"
                        style={{ width: '100%', height: 'auto'  }} // optional
                        />
                    </Reveal>
                  </div>
                    <Reveal>
                      <p  className="text-justify">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&apos;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum
                      </p>
                    </Reveal> */}
            </div>
          </div>
          {/* <Timeline /> */}

          
          <div className="p-4">
            <div className="max-w-5xl max-sm:max-w-sm mx-auto">
              <h2 className="text-4xl font-extrabold text-center">Our Certifications</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-12">
              <Reveal>
                      <img
                        src={gmp}
                        alt="videoShow"
                        className='max-w-[300px] mx-auto'
                        style={{ width: '100%', height: 'auto'  }} // optional
                        />
                    </Reveal>
                    <Reveal>
                      <img
                        src={iso}
                        alt="videoShow"
                        className='max-w-[300px] mx-auto'
                        style={{ width: '100%', height: 'auto'  }} // optional
                        />
                    </Reveal>
                    <Reveal>
                      <img
                        src={haccap}
                        alt="videoShow"
                        className='max-w-[300px] mx-auto'
                        style={{ width: '100%', height: 'auto'  }} // optional
                        />
                    </Reveal>
              </div>
            </div>
          </div>
          <div className="p-4">
            <div className="max-w-5xl max-sm:max-w-sm mx-auto">
              {ourTeam && ourTeam.length > 0 && <h2 className="text-4xl font-extrabold text-center">Meet Our Team</h2> }
              <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-8 text-center mt-12">
                {ourTeam && ourTeam.length > 0 ? ourTeam.map((item, index) => (
                  <Reveal>
                    <Cards key={index} item={item}/>
                  </Reveal>
                ))
                : null
                }
              </div>
            </div>
          </div>
        </div>

      <Footer />
      </section>
    </>
  )
}
