import ImportHeader from "./ImportHeader";
import Products from "./Products";
import Footer from "../../components/footer/index"

export default function Department() {

  return (
    <>
    <div id="main" className="bg-white gap-24 flex flex-col items-center justify-between">
        <ImportHeader />
        <Products />
        <Footer />
    </div>
    </>
  )
}
