import React from 'react'

export default function SliderComp({item , index}) {
  return (
    <div key={index} className="blog-slider__item swiper-slide max-w-screen">
    <div className="blog-slider__img">
        <img src={`https://webadmin.ceylonheritage.lk/public/product/${item.id}/${item.image}`} alt=""/>
    </div>
    <div className="blog-slider__content">
        <div className="blog-slider__title">{item.name}</div>
        <div className="blog-slider__text">{item.description}</div>
        {/* <a href="#" className="blog-slider__button">READ MORE</a> */}
    </div>
</div>
  )
}
