import React, { useRef, useState } from "react";

const itemsData = [
  {
    backgroundImage: './D.png',
    title: 'From where we import',
    description: `Our global sourcing team sticks to its stringent quality standards and specifications. Our team picks the product from the country with the highest quality, worldwide. Our team carries out a supplier audit prior to importing.`,
  },
  {
    backgroundImage: './high-angle-grains-sacks.png',
    title: 'Why we import',
    description: `In the realm of the food industry, we have discerned a prevailing trend where our fellow Sri Lankans predominantly consume unbranded, staple foods, often imported in large quantities. This influx of imports has regrettably given rise to numerous irregularities, resulting in the prevalence of substandard products with a limited shelf life. Recognizing the imperative need for change, Ceylon Heritage, a conscientious company armed with various global certifications, has committed itself to addressing this issue head-on. Our mission is to enhance the quality of staple foods available in Sri Lanka by importing high-grade products from around the world. In doing so, we aim not only to rectify the problem of substandard staples but also to introduce the finest global offerings to the people of our motherland, contributing to the well-being of Sri Lankans through a commitment to excellence and quality assurance.`,
  },
  {
    backgroundImage: './andy-li-CpsTAUPoScw-unsplash.jpg',
    title: 'What we import',
    description: `We import various types of dry food items, spices, grains, protein rich items, fruits and the details are as follows: 
    Sugar, dhal, chilli, bee oninion, small oninion, potatoe, wheat flour, fruits, garlic, sprats, canned fish, salt, cowpea, coriander, green gram, seeds, peanuts, rice, soya, noodles. 
    From where we import
    Our global sourcing team sticks to its stringent quality standards and specifications. Our team picks the product from the country with the highest quality, worldwide. Our team carries out a supplier audit prior to importing.`,
  },
];

export default function ImportHeader() {
    const sliderRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(1);
    
    const activate = (e) => {
      const items = sliderRef.current.querySelectorAll('.item');
      if (e.target.matches('.next')) {
        sliderRef.current.append(items[0]);
        setActiveIndex((prevIndex) => (prevIndex + 1) % items.length);
      }
      if (e.target.matches('.prev')) {
        sliderRef.current.prepend(items[items.length - 1]);
        setActiveIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
      }
    };
    
  return (
    <div className="min-h-screen">
    <ul className='slider flex flex-col' ref={sliderRef}>
    {itemsData.map((item, index) => (
          <li
            key={index}
            className={index === activeIndex ? 'item active' : index === (activeIndex + 1) % itemsData.length ? 'item hidden-item' : 'item'}
            style={{ backgroundImage: `url(${item.backgroundImage})` }}
          >
            <div className={index === activeIndex ? 'content active' : 'content'}>
              <h2 className='title'>{item.title}</h2>
              <p className='description'>{item.description}</p>
            </div>
          </li>
        ))}
  </ul>
  <nav className='nav flex gap-3'>
      <button className="prev px-2 py-2 text-white text-[28px]" onClick={activate}>{`<`}</button>
  <button className="next px-2 py-2 text-white text-[28px]" onClick={activate}>&gt;</button>
  </nav>
  </div>
  )
}
