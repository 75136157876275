import React from 'react'
import footerlogo from '../../assets/footerlogo.png'
export default function CareerCard({field}) {

    const handleClick = () => {
      window.location.href = `/careers/${field.id}/${field.name}`;
    }

  return (
    <div className='mt-4'>
    <div className="group bg-gray-900 p-4 transition-all duration-300  lg:p-8">
      
      <div className="flex items-center gap-x-2">
        <img className=" w-[78px]" src={footerlogo} alt="" />
        <div>
          <h3 className="text-xl font-bold text-gray-50">{field.name}</h3>
          <span className="text-xs text-gray-300">{field.location ? field.location : "Colombo"}</span>
        </div>
      </div>
      {/* <div className="my-4">
        <h3 className="text-[12px] font-medium text-gray-200">{field.description}</h3>
        <div className="text-sm font-medium">
          {field.skills.map((skill, index) => (
            <span key={index} className="m-1 ml-0 inline-block text-blue-500">{skill}</span>
          ))}
        </div>
        <div className="mt-2 text-sm text-gray-400">LRK {field.salary.min} - {field.salary.max} per year</div>
      </div> */}
      <div className="flex items-center justify-between">
        <span className="text-sm font-medium text-gray-50">{field.description}</span>
        <button onClick={() => handleClick()} className="font-medium text-blue-500 transition-all duration-300 group-hover:text-blue-500/80">See All Jobs</button>
      </div>
    </div>
  </div>
  )
}
