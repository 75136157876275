'use client'
import React from "react"
import { ToastContainer, toast } from 'react-toastify'
import axios from "axios"
import Logo from '../../assets/footerlogo.png'
import 'react-toastify/dist/ReactToastify.css'

export default function Footer() {
  const [email, setEmail] = React.useState("")

  const now = new Date()
  const currentYear = now.getFullYear()

  const handleSubmit = async () => {
    const data = {
      email: email,
    }
    if(email !== ""){
    axios
      .post(`https://webadmin.ceylonheritage.lk/api/subscription`, data)
      .then(() => {
        toast.info(
          "Thank you for subscribing to our newsletter. We will keep you updated with our latest news and offers."
        )
        setEmail("")
      })
      .catch((error) => {
        if(error.response.data.errors.email[0] !== undefined){
          toast.error(error.response.data.errors.email[0])
        }else{
          toast.error("Something went wrong. Please try again later.")
        }
      })
    }else{
      toast.info("Please enter your email")
    }
  }

  return (
    <footer className="bg-black higherIndexFooter max-w-screen w-full footer bottom-0 mt-[170px] pt-20 items-center">
      <ToastContainer />
      <div className="start-learning">
        <div className="footer-start gap-3 lg:gap-0 w-full flex flex-col lg:flex-row">
          <div className="texts lg:w-1/2 text-center w-full text-white ">
            <h2 className="section-title text-[20px] lg:text-[28px] font-bold">
              Join Our Newsletter
            </h2>
            <h3 className="section-sub-title text-[10px] lg:text-[3px] 2xl:text-[17px]">
              Sign up and we’ll send the best deals to you
            </h3>
          </div>
          <div className="relative lg:w-1/2 w-full text-[13px] 2xl:text-[17px]">
            <input
              type="text"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              className="h-14 pl-7 w-full pr-20 rounded-full z-0 focus:shadow focus:outline-none"
              placeholder="Type your email here"
            />
            <div className="absolute top-2 right-2">
              <button
                onClick={() => handleSubmit()}
                className="h-10 px-3.5 text-white rounded-full bg-black"
              >
                Subscribe
              </button>
            </div>
          </div>
          <div className="absolute top-0  hidden md:block left-2 opacity-50">
            <svg
              width="133"
              height="89"
              viewBox="0 0 227 142"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M53.7624 141.876C24.6517 123.1 5.82143 95.5545 1.41411 65.2983C-2.9932 35.0422 7.38345 4.55415 30.2613 -19.4588C53.1392 -43.4718 86.6442 -59.0426 123.406 -62.7459C160.167 -66.4492 197.174 -57.9816 226.285 -39.2059L194.799 -6.15828C176.314 -18.0808 152.815 -23.4577 129.471 -21.1061C106.128 -18.7545 84.8522 -8.86707 70.3248 6.38112C55.7974 21.6293 49.2083 40.9891 52.0069 60.2017C54.8055 79.4143 66.7627 96.9058 85.2479 108.828L53.7624 141.876Z"
                fill="#F3FCFB"
              />
            </svg>
          </div>
          <div className="absolute top-0 hidden md:block right-44 opacity-50">
            <svg
              width="133"
              height="89"
              viewBox="0 0 224 75"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M206.325 -75.0863C222.762 -52.0352 227.708 -24.7062 220.076 0.888377C212.443 26.483 192.857 48.2468 165.626 61.392C138.395 74.5371 105.75 77.9868 74.8716 70.9822C43.9937 63.9777 17.4124 47.0925 0.975521 24.0414L40.274 5.071C50.4197 19.2994 66.8271 29.7218 85.8866 34.0454C104.946 38.369 125.097 36.2397 141.905 28.1258C158.713 20.0119 170.803 6.57811 175.514 -9.22028C180.226 -25.0187 177.173 -41.8875 167.027 -56.1159L206.325 -75.0863Z"
                fill="#F3FCFB"
              />
            </svg>
          </div>
          <div className="absolute bottom-0 hidden md:block left-80 opacity-50">
            <svg
              width="133"
              height="89"
              viewBox="0 0 196 108"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M147.469 176.337C173.033 159.849 189.812 135.915 194.116 109.801C198.419 83.6857 189.894 57.5291 170.417 37.085C150.939 16.6409 122.104 3.58394 90.2547 0.786495C58.4057 -2.01095 26.1516 5.68026 0.58788 22.1682L28.6971 51.672C44.4764 41.4948 64.3853 36.7474 84.0443 38.4741C103.703 40.2008 121.502 48.2603 133.524 60.8795C145.547 73.4987 150.809 89.6439 148.153 105.763C145.497 121.883 135.14 136.656 119.36 146.833L147.469 176.337Z"
                fill="white"
                fillOpacity="0.42"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="">
        <div className="pb-10">
          <div className=" flex flex-col md:flex-row lg:space-x-0 md:space-x-7 space-x-0 space-y-3 lg:w-10/12 mx-auto flex-wrap items-center justify-center md:items-start">
            <div className="pt-2 flex flex-col items-center md:items-start lg:w-1/3 space-y-4">
              <div className="flex flex-row items-center space-x-2">
                <div>
                  <img
                    src={Logo}
                    width={120}
                    alt="footerlogo"
                    className="object-cover h-[70px]"
                  />
                </div>
              </div>
              <span className="text-[#fff] truncate whitespace-normal flex flex-col px-4 lg:px-0 text-[13px] 2xl:text-[17px]">
              Ceylon Heritage Collections: Sharing the best of Ceylon since 2011. Committed to excellence, ethical trade, and environmental care. Specializing in high-quality exports of fruits, vegetables, spices, leafy greens, king coconut, coconut oil and more. Join us in embracing diversity and fostering shared prosperity.
              </span>
            </div>
            <div className="flex flex-row lg:w-2/3 justify-around lg:space-x-0 space-x-16">
              <div className="flex flex-col text-center lg:text-left space-y-3 text-[13px] 2xl:text-[17px] text-white">
                <span className="font-bold text-[20px]">
                  Our Products
                </span>
                <a href="/import" className="text-[13px] hover:underline">Import</a>
                <a href="/export" className="text-[13px] hover:underline">Export</a>
                <a href="/foods" className="text-[13px] hover:underline">Foods</a>
              </div>
              <div className="flex flex-col text-center lg:text-left space-y-3 text-[13px] 2xl:text-[17px] text-white">
                <span className="font-bold text-[20px]">
                  Support
                </span>
                <a href="/our-gallery" className="text-[13px] hover:underline">Our Gallery</a>
                <a href="/about-us" className="text-[13px] hover:underline">About US</a>
                <a href="/contactus" className="text-[13px] hover:underline">Contact US</a>
                <a href="/careers" className="text-[13px] hover:underline">
                  Careers
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[#FFFFFF] ml-3 items-center lg:ml-8 w-11/12 h-[1px]"></div>
        <div className="w-11/12 ml-3 lg:ml-8 pt-6 pb-5">
          <div className="flex justify-center md:justify-start  flex-row items-center md:items-center">
            <div className=" text-white text-[10px] lg:text-[13px]">
              Copyright &copy; {currentYear} <a href="https://thealphaminds.com" target="_blank" rel="noreferrer">The Alpha Minds</a>
            </div>
            
          </div>
        </div>
      </div>
    </footer>
  )
}
