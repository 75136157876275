import React from "react";
import ProjectRoutes from "./routes/routes";

function App() {

  return (
      <ProjectRoutes /> 
  );
}
export default App;
