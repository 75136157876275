import ScrollAnimation from '../../components/scrollAnimation/index'
import React, { useEffect, useState }  from 'react'
import axios from 'axios'
import Reveal from '../../components/Reveal/Reveal'
import CareerCard from '../../components/CareerCard/CareerCard'
import Footer from '../../components/footer/index'

export default function Careers() {

  const [allJobs , setAllJobs] = useState([])

    const getCareers = async () => {
        try{
          const response = await axios.get(`https://webadmin.ceylonheritage.lk/api/careers`)
          console.log(response.data)
          setAllJobs(response.data)
        }catch(err){
          console.log(err)
        }
    }

    useEffect(() => {
        getCareers()
    },[])

  return (
    <>
      <ScrollAnimation />
      <section exit={{ opacity: 0 }} id="Careers" className="bg-white min-h-screen mt-[102px]">
        <div className=" flex flex-col  px-4 lg:w-10/12 mx-auto mt-16 space-y-12 md:space-y-0">
          <div
            className="flex flex-col gap-8"
          >
            <Reveal>
            < h1 className='flex items-center text-4xl font-bold py-10 w-full text-center justify-center'>Careers</h1>

            </Reveal>
            <div
              className="flex text-[#5C6A7A] flex-col text-[15px] gap-3"
            >
            <Reveal>
              <span >
              Join the Ceylon Heritage Collections family and become a part of our commitment to excellence, ethical trade, and environmental stewardship. We are always on the lookout for individuals who share our passion for quality produce and sustainable practices. Explore exciting career opportunities with us and contribute to our mission of sharing the best of Ceylon with the world. If you are dedicated, innovative, and share our core values of Integrity, Quality, Sustainability, Human Capital, and Innovation, we invite you to explore a rewarding career journey with CHC. Check out our current job openings and embark on a fulfilling path with us.
              </span>
            </Reveal>

            </div>
            <Reveal>
              {allJobs && allJobs.length > 0 ? allJobs.map((job, index) => (
                <CareerCard key={index} field={job}/>
              )) 
              :
              <div className="flex justify-center items-center">
                <span className="text-2xl font-bold text-black">No Jobs Available</span>
              </div>
            }
            {/* <CareerCard field={{ id : 1 }}/> */}
            </Reveal>
             
            <Reveal>
            <h2
              className="text-4xl mt-5 font-bold text-center text-black md:text-left"
            >
              {"Why join Us?"}
            </h2>
            </Reveal>
            <div
              className="flex text-[#5C6A7A] flex-col text-[15px] gap-3"
            >
            <Reveal>
              <span >
                Embark on a fulfilling career journey with Ceylon Heritage Collections, where your professional aspirations align with a company dedicated to excellence, ethical trade, and environmental care. As a global leader in the export of Ceylonese products, our impact extends far and wide, offering you the opportunity to contribute to a company with a vast and diverse customer base. Ceylon Heritage is constantly expanding our geographical footprint, CHC provides an exciting environment where you can be part of a team that is not only rooted in tradition but actively embraces growth into new markets.
              </span>
            </Reveal>
            <Reveal>
              <span >
                At the heart of our success lies an unwavering commitment to quality. Collaborating closely with growers, we ensure optimal harvest and meticulous processing, positioning ourselves as synonymous with other products. Joining us means becoming an integral part of a company that takes pride in delivering the very best of Ceylon to the world. Our core values—Integrity, Quality, Sustainability, Human Capital, and Innovation—form the bedrock of our work culture. At CHC, we encourage innovation and creativity, providing a platform for continuous learning and professional development.
              </span>
            </Reveal>
            <Reveal>
              <span >
              Furthermore, our workplace fosters an atmosphere where shared values drive collective success. Your dedication to these values will not only contribute to the company's ongoing achievements but will also shape your own rewarding career trajectory. Join Ceylon Heritage Collections, where innovation, growth, and a shared commitment to delivering the best of Ceylon to the world define our culture. Your journey with us goes beyond a job; it's a meaningful contribution to a global enterprise that values its people and their impact on the world stage.
              </span>
            </Reveal>
            </div>
          </div>
        </div>
      <Footer/>
      </section>
    </>
  )
}
