import React, { useState } from 'react';
import footerlogo from '../../assets/footerlogo.png';
import logo from '../../assets/logo.png';
import { useLocation } from 'react-router-dom';
import { SlArrowDown } from "react-icons/sl";

export default function Navigation() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();
  const [isOtherUrl , setIsOtherUrl] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = React.useRef(null);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const getLogoUrl = () => {
    // Add conditions based on your URL structure
    if (location.pathname === '/' || location.pathname === '/import' || location.pathname === '/export') {
      return footerlogo;
    } else {
      return logo;
    }
  };

  React.useEffect(() => {
    if(location.pathname === '/' || location.pathname === '/import' || location.pathname === '/export'){
      setIsOtherUrl(false);
    }else{
      setIsOtherUrl(true);
    }
  }, [location.pathname]);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Detach the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []); // Empty dependency array ensures that the effect runs once on mount

  return (
    <>
      <div className="max-w-screen w-full h-[80px] fixed top-0 shadow-lg shadow-[#2A0E61]/50 bg-[#03001417] backdrop-blur-md z-50 px-10">
        <div className="w-full h-full flex flex-row items-center justify-between m-auto px-[10px]">
          <a href="/" className="h-auto w-auto flex flex-row items-center">
            <img
              src={getLogoUrl()}
              alt="logo"
              width={130}
              height={100}
              className="cursor-pointer hover:animate-slowspin"
              style={{ mixBlendMode: 'multiply' }}
            />
          </a>

          <div className="w-[500px] h-full hidden md:flex flex-row items-center justify-between md:mr-20">
            <div className="flex items-center justify-between w-full h-auto mr-[15px] px-[20px] py-[10px] rounded-full text-gray-200">
              <a href="/" className={`cursor-pointer px-2 py-2 rounded-lg  nav-link ${isOtherUrl ? 'text-black hover:text-white' : 'text-white  hover:text-black'}`}>
                Home
              </a>
              <a href="/import" className={`cursor-pointer px-2 py-2 rounded-lg  nav-link ${isOtherUrl ? 'text-black  hover:text-white' : 'text-white  hover:text-black'}`}>
                Import
              </a>
              <a href="/export" className={`cursor-pointer px-2 py-2 rounded-lg  nav-link ${isOtherUrl ? 'text-black  hover:text-white' : 'text-white  hover:text-black'}`}>
                Export
              </a>
              {/* Dropdown Button */}
              <div className="relative">
                <button
                  onClick={toggleDropdown}
                  className={`cursor-pointer flex gap-2 items-center px-2 py-2 rounded-lg  nav-link ${isOtherUrl ? 'text-black  hover:text-white' : 'text-white  hover:text-black'}`}
                >
                  Company 
                  <SlArrowDown className={`${isOtherUrl ? 'text-black  hover:text-white' : 'text-white  hover:text-black'} text-[13px]`} />

                </button>

                {/* Dropdown Content */}
                {isDropdownOpen && (
                  <div ref={dropdownRef} className="absolute z-10 top-full left-0 mt-2 w-48 bg-white shadow-lg rounded-md overflow-hidden">
                     <a
                      href="/our-gallery"
                      className="block px-4 py-2 text-gray-800 hover:bg-black hover:text-white"
                      onClick={closeDropdown}
                    >
                      Our Gallery
                    </a>
                    <a
                      href="/careers"
                      className="block px-4 py-2 text-gray-800 hover:bg-black hover:text-white"
                      onClick={closeDropdown}
                    >
                      Careers
                    </a>
                    <a
                      href="/contactus"
                      className="block px-4 py-2 text-gray-800 hover:bg-black hover:text-white"
                      onClick={closeDropdown}
                    >
                      Contact Us
                    </a>
                    <a
                      href="/about-us"
                      className="block px-4 py-2 text-gray-800 hover:bg-black hover:text-white"
                      onClick={closeDropdown}
                    >
                      About Us
                    </a>
                    
                    {/* Add more dropdown items as needed */}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden cursor-pointer" onClick={toggleMobileMenu}>
            ☰
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden fixed top-[80px] left-0 w-full h-full bg-[#03001417] backdrop-blur-md z-50">
          <div className="flex flex-col items-center pt-10">
            <a href="/" className="cursor-pointer text-white px-2 py-2 rounded-lg  nav-link">
              Home
            </a>
            <a href="/import" className="cursor-pointer text-white px-2 py-2 rounded-lg  nav-link">
              Import
            </a>
            <a href="/export" className="cursor-pointer text-white px-2 py-2 rounded-lg  nav-link">
              Export
            </a>
            <a href="/our-gallery" className="cursor-pointer text-white px-2 py-2 rounded-lg  nav-link">
            Our Gallery
            </a>
            <a href="/careers" className="cursor-pointer text-white px-2 py-2 rounded-lg  nav-link">
              Careers
            </a>
            <a href="/contactus" className="cursor-pointer text-white px-2 py-2 rounded-lg  nav-link">
              Contact Us
            </a>
            <a href="/about-us" className="cursor-pointer text-white px-2 py-2 rounded-lg  nav-link">
              About Us
            </a>
            {/* Add more menu items as needed */}
          </div>
        </div>
      )}
    </>
  );
}
