'use client'
import axios from 'axios'
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from 'yup'
import { motion } from 'framer-motion'
import { useEffect , useState } from 'react'
import Lottie from "lottie-react"
import contactusAnimation from "../../assets/animations/contactusAnimation.json"
import { toast } from 'react-toastify'
import Footer from '../../components/footer/index'

const ContactUsSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  message: Yup.string().required("Message is required"),
})

const content = {
  animate: {
    transition: { staggerChildren: 0.1 },
  },
}

const title = {
  initial: { y: -20, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
}


export default function ContactUs() {

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    message: "",
  }

  const ContactUsSubmit = async (values, actions) => {
    console.log(values)
    await axios({
      method: "POST",
      url: "https://webadmin.ceylonheritage.lk/api/contact/submit",
      data: values,
    })
      .then(() => {
        actions.resetForm()
        toast.info("Thank you for contacting us! We will get back to you soon.")
      })
      .catch(() => {
        toast.info("Something went wrong. Please try again later.")
      })
      .finally(() => {
        actions.setSubmitting(false)
      })
  }

  const [showContent, setShowContent] = useState(false)

  useEffect(() => {
    // Set showContent to true after 2 seconds
    const timeoutId = setTimeout(() => {
      setShowContent(true)
    }, 700)

    // Clear the timeout to prevent memory leaks
    return () => clearTimeout(timeoutId)
  }, [])


  return (
    <>
    <motion.div
        exit={{ opacity: 0 }}
        className=" w-full lg:w-10/12 mt-7 mx-auto" id="contact">
      <motion.div variants={content} animate="animate" initial="initial" className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-16 text-center">
    <motion.h1 className='flex items-center text-4xl font-bold py-10 w-full text-center justify-center'>Contact Us</motion.h1>
        
        {/* <motion.h2 variants={title} className="text-4xl font-bold">Contact Us</motion.h2> */}
        <motion.p variants={title} className="pt-6 pb-6 text-base max-w-2xl text-center m-auto ">
        Feel free to reach out to us at Ceylon Heritage Collections for any inquiries, collaborations or to connect with our team dedicated to excellence in Sri Lankan produce and global trade.
        </motion.p>
      </motion.div>
      <motion.div variants={content} animate="animate" initial="initial"  className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-8 pb-16 grid md:grid-cols-2 lg:grid-cols-2 gap-y-8 md:gap-x-8 md:gap-y-8 lg:gap-x-8 lg:gap-y-16">
        <motion.div variants={content} animate="animate" initial="initial" >
        <Lottie animationData={contactusAnimation} className='w-[340px]' loop={true} />
          <motion.h2 variants={title} className="text-lg font-bold">Contact Us</motion.h2>
          <motion.p variants={title} className="max-w-sm mt-4 mb-4 ">Have something to say? We are here to help. Fill up the form or send an email or call phone.</motion.p>
          <motion.div variants={content} animate="animate" initial="initial"  className="flex items-center mt-8 space-x-2 text-dark-600 ">
            <motion.svg variants={title}  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" className="w-4 h-4">
              <motion.path variants={title}  stroke-linecap="round" stroke-linejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z"></motion.path>
            </motion.svg>
            <motion.span variants={title} >Lindale Industrial State, Sapugaskanda, Sri Lanka</motion.span>
          </motion.div>
          <motion.div variants={content} animate="animate" initial="initial"  className="flex items-center mt-2 space-x-2 text-dark-600 ">
            <motion.svg variants={title}  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" className="w-4 h-4">
              <motion.path variants={title}  stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"></motion.path>
            </motion.svg>
            <motion.a variants={title} href="mailto:info@ceylonheritage.lk">info@ceylonheritage.lk</motion.a>
          </motion.div>
          <motion.div variants={content} animate="animate" initial="initial"  className="flex items-center mt-2 space-x-2 text-dark-600 ">
            <motion.svg variants={title}  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" className="w-4 h-4">
              <motion.path variants={title}  stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"></motion.path>
            </motion.svg>
            <motion.a variants={title} href="tel:+94774100093">+94 77 410 0093</motion.a>
          </motion.div>
        </motion.div>
        <div>
          <div className={`${!showContent ? 'opacity-0' : 'slide-in-contacts'}`}>
        <Formik
                initialValues={initialValues}
                validationSchema={ContactUsSchema}
                onSubmit={ContactUsSubmit}
              >
                 {(formik) => {
                  const { errors, touched } = formik
                  return (
                    <Form>

            <Field type="checkbox" id="" className="hidden" style={{ display: 'none' }} name="botcheck" />
            <div className="mb-5 ">
                          {/* <span className="text-[15px]">First Name</span> */}
                          <Field
                            id="first_name"
                            name="first_name"
                            type="name"
                            autoComplete="first_name"
                            required
                            placeholder="Enter your first name"
                            className={`relative block w-full appearance-none  rounded-md border border-gray-300 px-3 py-3 text-gray-900 placeholder-gray-500 focus:z-10 focus:outline-none sm:text-sm ${
                              errors.first_name && touched.first_name
                                ? "border-red-500"
                                : null
                            }`}
                          />
                          <ErrorMessage
                            name="first_name"
                            component="span"
                            className="text-red-500"
                          />
                        </div>
            <div className="mb-5 ">
                          {/* <span className="text-[15px]">Last Name</span> */}
                          <Field
                            id="last_name"
                            name="last_name"
                            type="name"
                            autoComplete="last_name"
                            required
                            placeholder="Enter your last name"
                            className={`relative block w-full appearance-none  rounded-md border border-gray-300 px-3 py-3 text-gray-900 placeholder-gray-500 focus:z-10 focus:outline-none sm:text-sm ${
                              errors.last_name && touched.last_name
                                ? "border-red-500"
                                : null
                            }`}
                          />
                          <ErrorMessage
                            name="last_name"
                            component="span"
                            className="text-red-500"
                          />
                        </div>
            <div className="mb-5 ">
                          {/* <span className="text-[15px]">Email ID</span> */}
                          <Field
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            placeholder="Enter your email"
                            className={`relative block w-full appearance-none  rounded-md border border-gray-300 px-3 py-3 text-gray-900 placeholder-gray-500 focus:z-10 focus:outline-none sm:text-sm ${
                              errors.email && touched.email
                                ? "border-red-500"
                                : null
                            }`}
                          />
                          <ErrorMessage
                            name="email"
                            component="span"
                            className="text-red-500"
                          />
                        </div>
                        <div className="mb-5 ">
                          {/* <span className="text-[15px]">Message</span> */}
                          <Field
                          component="textarea" rows="4"
                            id="message"
                            name="message"
                            autoComplete="message"
                            required
                            placeholder="Enter your message"
                            className={`relative block w-full appearance-none  rounded-md border border-gray-300 px-3 py-3 text-gray-900 placeholder-gray-500 focus:z-10 focus:outline-none sm:text-sm ${
                              errors.message && touched.message
                                ? "border-red-500"
                                : null
                            }`}
                          />
                          <ErrorMessage
                            name="message"
                            component="span"
                            className="text-red-500"
                          />
                        </div>
            <button type="submit" className="w-full  py-4 font-semibold text-white transition-colors bg-neutral-900 rounded-md hover:bg-neutral-800 focus:outline-none focus:ring-offset-2 focus:ring focus:ring-neutral-200 px-7  ">Send Message</button>
          </Form>
            )
          }}
          </Formik>
        </div>
        </div>
      </motion.div>
    </motion.div>
    <Footer/>
    </>
  )
}
