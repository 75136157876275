import React , {useRef , useEffect} from 'react'
import { motion , useInView , useAnimation } from 'framer-motion'

export default function Reveal({ children , width = '100%' }) {
    const ref = useRef()
    const isInView = useInView(ref , {once: true})
    const mainControls = useAnimation()
    // const slideControls = useAnimation()

    useEffect(() => {
        if(isInView){
            mainControls.start('visible')
            // slideControls.start('visible')
        }
    } , [isInView])

  return (
    <div ref={ref} style={{ position : 'relative' , width  , overflow : 'hidden' }}>
    <motion.div
        initial='hidden'
        animate={mainControls}
        transition={{ duration: 0.5 , delay : 0.5 }}
        variants={{
            hidden: {
                opacity: 0,
                y : 75,
            },
            visible: {
                opacity: 1,
                y : 0,
            }
        }}
    >
        {children}
    </motion.div>
 
    </div>
  )
}
